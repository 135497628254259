// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Iconoir } from "https://framerusercontent.com/modules/zL9598C4KbEbqUGvSR14/rI8sPHpnG9XGcCPc0vU4/Iconoir.js";
const IconoirFonts = getFonts(Iconoir);
const IconoirControls = getPropertyControls(Iconoir);

const enabledGestures = {rIFbwjzR_: {hover: true}};

const serializationHash = "framer-Gmx7i"

const variantClassNames = {rIFbwjzR_: "framer-v-1was049"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, GJw91rApb: icon ?? props.GJw91rApb ?? "Instagram", hp7S_Q0qj: link ?? props.hp7S_Q0qj} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;icon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hp7S_Q0qj, GJw91rApb, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "rIFbwjzR_", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={hp7S_Q0qj} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1was049", className, classNames)} framer-1tnrvag`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"rIFbwjzR_"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"rIFbwjzR_-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Transition value={transition1}><ComponentViewportProvider ><motion.div className={"framer-1cacc4o-container"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"NlLQNRVgY-container"} name={"Icon"}><Iconoir color={"var(--token-6145797c-db14-4e39-8951-2131725399fb, rgb(163, 163, 163))"} height={"100%"} iconSearch={"Home"} iconSelection={GJw91rApb} id={"NlLQNRVgY"} layoutId={"NlLQNRVgY"} mirrored={false} name={"Icon"} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"rIFbwjzR_-hover": {color: "var(--token-33722da1-56ef-4815-82ed-442105eb06b1, rgb(0, 0, 0))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></Transition></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Gmx7i.framer-1tnrvag, .framer-Gmx7i .framer-1tnrvag { display: block; }", ".framer-Gmx7i.framer-1was049 { cursor: pointer; height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 24px; }", ".framer-Gmx7i .framer-1cacc4o-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"CvfeWtD6t":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"hp7S_Q0qj":"link","GJw91rApb":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramereQEtV0cno: React.ComponentType<Props> = withCSS(Component, css, "framer-Gmx7i") as typeof Component;
export default FramereQEtV0cno;

FramereQEtV0cno.displayName = "Social Icon";

FramereQEtV0cno.defaultProps = {height: 24, width: 24};

addPropertyControls(FramereQEtV0cno, {hp7S_Q0qj: {title: "Link", type: ControlType.Link}, GJw91rApb: IconoirControls?.["iconSelection"] && {...IconoirControls["iconSelection"], defaultValue: "Instagram", description: undefined, hidden: undefined, title: "Icon"}} as any)

addFonts(FramereQEtV0cno, [{explicitInter: true, fonts: []}, ...IconoirFonts], {supportsExplicitInterCodegen: true})